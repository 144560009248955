<template>
  <div>
    <div>
      <navbar title="关于沪惠学"></navbar>
      <!-- <div class="b">
        <baidu-map
          class="map"
          :center="{ lng: 116.404, lat: 39.915 }"
          :zoom="15"
        >
          <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
          <bm-geolocation
            anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
            :showAddressBar="true"
            :autoLocation="true"
          ></bm-geolocation>
          <bm-marker
            :position="{ lng: 116.404, lat: 39.915 }"
            :dragging="true"
            animation="BMAP_ANIMATION_BOUNCE"
          >
            <bm-label
              content="我爱北京天安门"
              :labelStyle="{ color: '#000', fontSize: '0.24rem' }"
              :offset="{ width: -35, height: 30 }"
            />
          </bm-marker>
          <bm-marker
            title="对对对"
            :position="{ lng: 121.48053886, lat: 31.23592904 }"
            :dragging="true"
            animation="BMAP_ANIMATION_DROP"
          >
          </bm-marker>
        </baidu-map>
      </div> -->
      <div class="box">
        <div v-html="content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      content: "",
    };
  },
  methods: {
    ...mapActions(["getabout"]),
  },
  mounted() {
    this.getabout({}).then((res) => {
      this.content = res.ResponseBody.content;
    });
  },
};
</script>

<style lang='scss' scoped>
.box {
  padding: 0 0.2rem;
  box-sizing: border-box;
}
.map {
  width: 100%;
  height: 5rem;
}
</style>